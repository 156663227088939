"use client";

import Rocket from "../ui/rocket/page";
import React, { useState, useEffect } from "react";
import MainPage from "./main/page";


export default function Home() {
  
  return (
    <div className="app">
      {/* <div className="stars"></div> */}
      {/* <div className="cursor" style={cursorStyle}></div> */}
      {/* <div className="beam" style={beamStyle}></div> */}
      {/* Your app content here */}
      {/* <Rocket /> */}
      <MainPage />
    </div>
  );
};